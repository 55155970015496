import { ImpersonateForm } from '../components/forms/ImpersonateForm';
import T from '@mui/material/Typography';

export const ImpersonatePage = () => {
    const impersonateEmail = localStorage.getItem('impersonateEmail' );

    const impersonate = ({ email }) => {
        localStorage.setItem('impersonateEmail' , email);
        window.location.reload();
    }

    const reset = () => {
        localStorage.removeItem('impersonateEmail' );
        window.location.reload();
    }

    if (impersonateEmail) {
        return <>
            <T align="center" sx={{mb: 2}}>Impersonated as: <b>{localStorage.getItem('impersonateEmail' )}</b></T>
            <T align="center"><button onClick={reset}>Reset</button></T>
        </>
    }

    return <ImpersonateForm onSubmit={impersonate}/>
};