import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';

import T from '@mui/material/Typography';
import Box from '@mui/material/Box';

const SignInSignUpButtonComp = ({ children, signUp, ...props }) => {
    const { loginWithRedirect } = useAuth0();

    return (
        <button
            onClick={() => loginWithRedirect({
                appState: { returnTo: window.location.pathname },
                ...(signUp ? {screen_hint: 'signup'} : {})
            })}
            {...props}
        >
            {children}
        </button>
    )
}

const SignInSignUpButton = styled(SignInSignUpButtonComp)`
  display: inline-block;
  color: #fff;
  background: #000;
  margin: 0.25rem;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 1rem 0.5rem;
  width: 18rem;
  cursor: pointer;
`;

export const SKSAuthLandingComp = ({ className }) => (
    <Box className={className}>
        <T variant="h3" component="h1">What is SKS feedback?</T>
        <T sx={{ fontSize: 18, mt: 5 }}>
            A process where you ask others what you should Stop, Keep, Start doing, given the particular role we
            might have as a teacher, friend, spouse, father, mother, etc.
        </T>
        <Box sx={{p: 3, my: 5}}>
            <SignInSignUpButton><T>Sign In</T></SignInSignUpButton>
            <SignInSignUpButton signUp><T>Sign Up</T></SignInSignUpButton>
        </Box>
        <T sx={{fontSize: 14}}>*We need you to sign up to provide feedback for the individual that requested your feedback. This is our verification method for security purposes so individuals only receive feedback from those they requested feedback from.</T>
        <T sx={{fontSize: 14}}>*We will not share your email, spam you, or send any marketing correspondences to this email. This is purely for verification purposes.</T>
    </Box>
);

export const SKSAuthLanding = styled(SKSAuthLandingComp)`
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
`;