export const CONSTANTS = (function (env) {
    console.log(`Environment: ${env}`)
    let conf = {
        AUTH_REDIRECT_URI: window.location.origin,
        AUTH_AUDIENCE: 'sksfeedback',
        AUTH_SCOPE: 'read:current_user update:current_user_metadata'
    };
    switch (env) {
        case 'dev': {
            conf = {
                ...conf,
                AUTH_DOMAIN: 'hos-dev.us.auth0.com',
                AUTH_CLIENT_ID: 'fQIY1wqzWBWvVRQsXxGJpr77wepT8rwi',
                API_DOMAIN: 'https://dev.api.sks.hosinc.co'
            }
            break;
        }
        case 'staging': {
            conf = {
                ...conf,
                AUTH_DOMAIN: 'hos-staging.us.auth0.com',
                AUTH_CLIENT_ID: '5JLOqBkJGMS4pWM59ewcbUUZa3ldrjCC',
                API_DOMAIN: 'https://staging.api.sks.hosinc.co'
            }
            break;
        }
        case 'prod': {
            conf = {
                ...conf,
                AUTH_DOMAIN: 'hos-prod.us.auth0.com',
                AUTH_CLIENT_ID: 'YgiDkdn3p1cneCmeNJjRInTHKyREDzgd',
                API_DOMAIN: 'https://api.sks.hosinc.co'
            }
            break;
        }
        // Default is local
        default: {
            conf = {
                ...conf,
                AUTH_DOMAIN: 'hos-dev.us.auth0.com',
                AUTH_CLIENT_ID: 'fQIY1wqzWBWvVRQsXxGJpr77wepT8rwi',
                API_DOMAIN: 'http://0.0.0.0:5000'
            }
            break;
        }
    }
    return conf;
})(process.env.REACT_APP_FRONTEND_ENVIRONMENT);