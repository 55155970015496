import T from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import { useStateContext } from '../context/State';
import { LinkButton } from '../components/LinkButton';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { RequestsPage } from './RequestsPage';
import { isSetEqual } from '../helpers/utils';

const BlogLink = ({ text }) => (
    <Link href="https://hbr.org/2011/08/three-questions-for-effective-feedback" target="_blank">{text}</Link>
)

const FormLink = ({ text }) => (
    <Link component={RouterLink} color="primary" to="/survey-form-sample">
        {text}
    </Link>
)

const Description = () => (
    <>
        <T textAlign="center" variant="h4" component="h1" sx={{ mb: 3 }}>
            How does SKS Feedback work?
        </T>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <ul>
                <li><T>We base our feedback model on SKS, which you can read about <BlogLink text="here"/></T></li>
                <li><T>Click <FormLink text="here"/> to see the feedback form sample</T></li>
                <li><T>Only you will see your feedback</T></li>
                <li><T>Those submitting feedback have the option to remain anonymous</T></li>
            </ul>
        </Box>
    </>
)

export const HomePage = () => {
    const { state: { surveys, roles } } = useStateContext();

    if (isSetEqual(roles, new Set(['respondent']))) {
        return <RequestsPage/>
    }

    return (
        <>
            <Container maxWidth="lg" sx={{ mb: 5, border: 1, borderRadius: 1, borderColor: 'info.main', p: 2 }}>
                <Description/>
            </Container>
            {surveys?.map((s) => (
                <Paper key={s.surveyId} sx={{ mb: 3, display: 'flex', alignItems: 'center' }}>
                    <T sx={{mr: 'auto', pl: 1}}>{s.name || 'Unnamed Survey'}</T>
                    <LinkButton to={`survey/${s.surveyId}/edit`}>Edit</LinkButton>
                    <LinkButton to={`survey/${s.surveyId}/responses`}>Responses</LinkButton>
                </Paper>
            ))}
        </>
    )
};