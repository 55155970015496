import { useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useSnackbar } from 'notistack';

import { pluralize, useHTTPRequest } from '../helpers/utils';
import { CONSTANTS } from '../constants';

export const ResponseReminder = ({ surveyId, requestedAmount, responsesAmount }) => {
    const { request } = useHTTPRequest();
    const { enqueueSnackbar } = useSnackbar();
    const [isSubmitting, setIsSubmitting] = useState(false);

    if (requestedAmount === 0) {
        return <Typography align="center" variant="h5">You haven't sent any survey requests yet.</Typography>
    }

    const handleClick = async () => {
        setIsSubmitting(true);
        const remindersCount = requestedAmount - responsesAmount;

        try {
            const { status } = await request(`${CONSTANTS.API_DOMAIN}/remind`, {
                method: 'POST',
                body: { surveyId }
            });
            if (status === 200) {
                enqueueSnackbar(`${pluralize(remindersCount, 'Reminder', 's')} sent.`, { variant: 'success' });
            } else {
                enqueueSnackbar('You can remind only once per day.', { variant: 'error' });
            }
            setIsSubmitting(false);
        } catch (err) {
            setIsSubmitting(false);
            enqueueSnackbar(`Cannot send reminder${remindersCount > 0 ? 's' : ''}`, { variant: 'error' });
            console.error('Error', err);
        }
    }

    const headerText = `You have ${responsesAmount} ${pluralize(responsesAmount, 'response')} out of ${requestedAmount} feedback ${pluralize(requestedAmount, 'request')}.`

    if (responsesAmount < requestedAmount) {
        return (
            <Paper sx={{ p: 2, mb: 5 }}>
                <Typography align="center" variant="h5">{headerText}</Typography>
                <Typography sx={{ my: 1, fontSize: 14 }} align="center" color="text.secondary">
                    Clicking this button will send an email to each person who has not provided feedback yet.
                </Typography>
                <Typography align="center">
                    <LoadingButton
                        onClick={handleClick}
                        type="submit"
                        endIcon={<NotificationsIcon/>}
                        loading={isSubmitting}
                        loadingPosition="end"
                        variant="contained"
                    >
                        Remind
                    </LoadingButton>
                </Typography>
            </Paper>
        )
    }

    return null;
};