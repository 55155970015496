import { Switch } from 'react-router-dom';
import { AppRoute as Route } from './components/AppRoute';

import { HomePage } from './pages/HomePage';
import { RequestsPage } from './pages/RequestsPage';
import { EditSurveyPage } from './pages/EditSurvey';
import { ResponsesPage } from './pages/ResponsesPage';
import { SurveyPage, SurveyPageSample } from './pages/SurveyPage';
import { ImpersonatePage } from './pages/ImpersonatePage';
import { AboutPage } from './pages/About';


export const AppRoutes = () => (
    <Switch>
        <Route isPrivate exact path="/">
            <HomePage/>
        </Route>
        <Route isPrivate exact path="/requests">
            <RequestsPage/>
        </Route>
        <Route isPrivate exact path="/survey/:id/edit">
            <EditSurveyPage/>
        </Route>
        <Route isPrivate exact path="/survey/:id/responses">
            <ResponsesPage/>
        </Route>
        <Route isPrivate exact path="/survey/:id">
            <SurveyPage/>
        </Route>
        <Route isPrivate exact path="/impersonate-user" allowedRoles={new Set(['admin'])}>
            <ImpersonatePage/>
        </Route>
        <Route exact path="/about-hos-basecamp">
            <AboutPage/>
        </Route>
        <Route exact path="/survey-form-sample">
            <SurveyPageSample/>
        </Route>
        <Route exact path="/survey/:id/:inviteId">
            <SurveyPage/>
        </Route>
    </Switch>
);