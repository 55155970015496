import { useMemo } from 'react';
import { useForm, useController, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import { useStateContext } from '../../context/State';
import { useHTTPRequest } from '../../helpers/utils';
import { CONSTANTS } from '../../constants';

const SurveyFormInput = ({ title, description, required, rules, control, name, ...inputProps }) => {
    return (
        <Box>
            <Typography align="center" sx={{ mt: 5, fontSize: 18 }} variation="h5">{title}</Typography>
            <Typography sx={{ fontSize: 16, my: 2 }}>{description}</Typography>
            <Controller
                name={name}
                rules={rules}
                control={control}
                render={({ field, formState }) => {
                    return (
                        <TextField
                            required={rules?.required}
                            rows={4}
                            disabled={formState.isSubmitting}
                            fullWidth
                            multiline
                            error={Boolean(formState.errors?.[name])}
                            {...inputProps}
                            {...field}
                        />
                    )
                }}
            />
        </Box>
    )
};

const CheckboxField = ({ label, control, name, defaultValue }) => {
    const {
        field,
        formState: { isSubmitting }
    } = useController({
        name,
        control,
        defaultValue,
    });

    return <FormControlLabel
        label={label}
        disabled={isSubmitting}
        checked={field.value}
        control={
            <Checkbox
                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                checked={field.value}
                {...field}
            />
        }
    />
}

const getCachedResponse = (surveyId) => {
    try {
        return JSON.parse(localStorage.getItem(`SURVEY_RESPONSE_${surveyId}`));
    } catch (e) {
        return null;
    }
}

export const SurveyResponseForm = ({ isSample, surveyId, inviteId, toEmail, onSubmit }) => {
    const { dispatch } = useStateContext();
    const { request } = useHTTPRequest();
    const defaultValues = useMemo(() => getCachedResponse(surveyId), [surveyId]);
    const formContext = useForm({
        mode: 'onChange',
        defaultValues,
    });
    const { control, handleSubmit, formState: { isSubmitting, isDirty, isValid } } = formContext;

    const onSubmitSurvey = async (values) => {
        try {
            const { start, keep, stop, words, skills, activities, anonymous } = values;
            localStorage.setItem(`SURVEY_RESPONSE_${surveyId}`, JSON.stringify(values));
            const { data, status } = await request(`${CONSTANTS.API_DOMAIN}/response/${surveyId}/${inviteId}`, {
                withoutAuth: true,
                method: 'POST',
                body: {
                    start,
                    keep,
                    stop,
                    words,
                    skills,
                    activities,
                    anonymous
                }
            });
            if (data?.errors || status !== 200) {
                onSubmit({ success: false });
            } else {
                localStorage.removeItem(`SURVEY_RESPONSE_${surveyId}`);
                dispatch({
                    type: 'sendResponse',
                    payload: {
                        surveyId,
                        response: data
                    }
                });
                // TODO: Fix this
                setTimeout(() => onSubmit({ success: true }));
            }
        } catch (err) {
            console.error('Error adding survey response', err);
            onSubmit({ success: false });
        }
    }

    return (
        <Box>
            <form onSubmit={handleSubmit(onSubmitSurvey)}>
                <SurveyFormInput
                    title={<>What are 3 things that {toEmail} should STOP doing?</>}
                    description={<>These can be habits, behaviors, or actions that you observe {toEmail} doing that you
                        think has a negative impact on their life or the lives of those close to them. Examples: stop
                        interrupting, stop smoking, stop losing your temper, stop running late.</>}
                    label="Stop doing"
                    control={control}
                    name="stop"
                    rules={{ required: true }}
                />
                <SurveyFormInput
                    title={<>What are 3 things that {toEmail} should KEEP doing?</>}
                    description={<>These can be habits, behaviors, or actions that you observe {toEmail} doing that you
                        think they should keep doing. These are the positive things that the individual does that have a
                        positive impact on their life or the lives of those close to them. Examples: keep expressing
                        gratitude, keep following-through on your promises, keep asking hard questions, keep making me
                        laugh.</>}
                    label="Keep doing"
                    control={control}
                    name="keep"
                    rules={{ required: true }}
                />
                <SurveyFormInput
                    title={<>What are 3 things that {toEmail} should START doing?</>}
                    description="These can be habits, behaviors or actions that the individual does not do that you think they should start doing that will have a positive impact on their lives or the lives of those close to them. Examples: start communicating more proactively, start getting organizing your tasks, start listening more, start putting down your phone."
                    label="Start doing"
                    control={control}
                    name="start"
                    rules={{ required: true }}
                />
                <SurveyFormInput
                    title={<>What words and/or phrases would you use to describe {toEmail}?</>}
                    description="These can be positive, neutral, or negative. Examples: persistent, insincere, genuine, egotistical, patient, dishonest, ambitious, unreliable, modest, careless, generous, greedy, honest, rude, reliable."
                    label="Words to describe the individual"
                    control={control}
                    name="words"
                    rules={{ required: true }}
                />
                <SurveyFormInput
                    title={<>What skills and/or knowledge should {toEmail} improve or learn?</>}
                    description="Use this space to provide suggestions on what skills or knowledge areas that the individual should improve upon or learn. Examples: coding, cooking, public speaking, budgeting, hockey, graphic design, project management, welding. This question is optional."
                    label="Skills and/or knowledge to improve or learn"
                    control={control}
                    name="skills"
                />
                <SurveyFormInput
                    title={<>What activities should {toEmail} spend more or less time on?</>}
                    description="Perspectives of others can help identify potential adjustments needed in where time is spent. Examples: spend more time developing skills, spend more time with your family, spend less time on your phone, spend less time commuting. This question is optional."
                    label="Activities to spend more or less time on"
                    control={control}
                    name="activities"
                />
                <Typography align="center" sx={{ my: 5 }} variation="h5">
                    If you want to identify yourself for the individual who requested the feedback, uncheck the box
                    below.
                </Typography>
                <Typography color="text.secondary" align="center" sx={{ fontSize: 14 }}>
                    THIS IS COMPLETELY OPTIONAL AND THERE IS NO OBLIGATION TO IDENTIFY YOURSELF.
                </Typography>
                <Typography align="center" sx={{ my: 3 }}>
                    <CheckboxField
                        label="Send anonymously"
                        name="anonymous"
                        defaultValue={true}
                        control={control}
                    />
                </Typography>
                <Typography align="center" sx={{ my: 3 }}>
                    <LoadingButton
                        sx={{ mx: 2 }}
                        type="submit"
                        endIcon={<SendIcon/>}
                        loading={isSubmitting}
                        disabled={isSample || !(isDirty || defaultValues) || !isValid}
                        loadingPosition="end"
                        variant="contained"
                    >
                        {isSample ? 'It\'s just a sample' : 'Submit Feedback'}
                    </LoadingButton>
                </Typography>
            </form>
        </Box>
    )
};