import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { ResponsesCountdown } from '../components/ResponsesCountdown';
import { ResponseReminder } from '../components/ResponseReminder';
import { ResponseCard } from '../components/ResponseCard';
import { pluralize } from '../helpers/utils';
import { useStateContext } from '../context/State';

export const ResponsesPage = () => {
    const { id: surveyId } = useParams();
    const { state: { surveys } } = useStateContext();
    const survey = surveys.find(s => s.surveyId === surveyId);

    const responsesAvailable = new Date() - new Date(survey.responsesVisibilityDatetime) > 0;
    
    const responsesList = survey.responses.map((row, index) => (
        <Accordion key={index} disabled={!responsesAvailable}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls={`response-${index}-content`}
                id={`response-${index}-header`}
            >
                <Typography noWrap color="text.secondary">
                    {row.respondent ?? 'Anonymous'}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <ResponseCard response={row} key={index}/>
            </AccordionDetails>
        </Accordion>
    ));

    return <Box>
        {!responsesAvailable ? (
            <ResponseReminder
                surveyId={survey.surveyId}
                requestedAmount={survey.invites.length}
                responsesAmount={survey.responses.length}
            />
        ) : null}
        {responsesList.length ? (
            <Box sx={{ my: 2 }}>
                <ResponsesCountdown responsesVisibilityDatetime={survey.responsesVisibilityDatetime}/>
            </Box>
        ) : null}
        {responsesList.length ? (
            <Typography
                color="text.secondary"
                align="center"
                sx={{ mb: 2 }}
            >
                You've received {pluralize(responsesList.length, 'feedback', 's')} from:
            </Typography>
        ) : null}
        <Box>{responsesList}</Box>
    </Box>;
}