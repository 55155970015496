import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import { emailPattern } from '../../helpers/utils';

export const ImpersonateForm = ({ onSubmit }) => {
    const { register, handleSubmit, formState: { errors, isSubmitting, isDirty, isValid } } = useForm({
        mode: 'onChange'
    });

    const onFormSubmit = async (values) => onSubmit(values);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <TextField
                    sx={{ minWidth: 300, flexGrow: 1, mb: 2 }}
                    label="Email"
                    required
                    variant="outlined"
                    disabled={isSubmitting}
                    error={Boolean(errors?.email)}
                    helperText={errors?.email?.message || ' '}
                    {...register('email', { required: true, pattern: emailPattern })}
                />
                <LoadingButton
                    sx={{ mx: 2, mb: 2, p: 2 }}
                    variant="contained"
                    type="submit"
                    endIcon={<SendIcon/>}
                    loading={isSubmitting}
                    disabled={!isDirty || !isValid}
                    loadingPosition="end"
                >
                    Save
                </LoadingButton>
            </form>
        </Box>
    )
};