import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

export const FooterComp = ({ className }) => (
    <Typography className={className} align="center" sx={{ mt: 5, p: 2 }}>
        &copy;{new Date().getFullYear()} hOS, Inc - All Rights Reserved.
    </Typography>
);

export const Footer = styled(FooterComp)`
  @media print {
    display: none;
  }
`;