import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

export const RespondentsTable = ({ invites }) => (
    <>
        <Typography align="center" color="text.secondary" sx={{ mb: 2 }}>
            {invites.length ? 'Here is the list of invited people.' : 'You haven\'t invited anyone yet.'}
        </Typography>
        {invites.length ? invites.map(({ name, email }) => (
            <Paper sx={{ mb: 2, py: 1, px: 2 }} key={email}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    <b>Name:</b> {name}
                </Typography>
                <Typography sx={{ fontSize: 14 }} color="text.secondary">
                    <b>Email: </b> {email}
                </Typography>
            </Paper>
        )) : null}
    </>
);