import { StrictMode } from 'react';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { Auth0Provider } from '@auth0/auth0-react';

import { CONSTANTS } from './constants';
import { NotificationsProvider } from './components/NotificationsProvider';
import { StateProvider } from './context/State';
import { AppRoutes } from './AppRoutes';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    border: 0;
    -webkit-font-smoothing: antialiased;
  }

  #root {
    min-height: 100vh;
  }
`;

const AuthProvider = ({ children }) => {
    let history = useHistory();

    return (
        <Auth0Provider
            domain={CONSTANTS.AUTH_DOMAIN}
            clientId={CONSTANTS.AUTH_CLIENT_ID}
            redirectUri={CONSTANTS.AUTH_REDIRECT_URI}
            onRedirectCallback={appState => history.replace(appState.returnTo)}
            audience={CONSTANTS.AUTH_AUDIENCE}
            scope={CONSTANTS.AUTH_SCOPE}
        >
            {children}
        </Auth0Provider>
    )
}

export const App = () => {
    return (
        <NotificationsProvider maxSnack={3}>
            <StrictMode>
                <Router>
                    <AuthProvider>
                        <GlobalStyle/>
                        <StateProvider>
                            <AppRoutes/>
                        </StateProvider>
                    </AuthProvider>
                </Router>
            </StrictMode>
        </NotificationsProvider>
    )
};