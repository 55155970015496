import { useCallback, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import camelcase from 'camelcase-keys';
import snakecase from 'snakecase-keys';

export const pluralize = (count, noun, suffix = 's') => `${noun}${count > 1 || count === 0 ? suffix : ''}`;

export const countUnfilledInvites = (requests) => (requests || []).filter(({ response }) => !response).length;

export const formatDate = (date) => new Date(date).toLocaleString('en-US', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    hour12: true,
    hour: 'numeric',
    timeZoneName: 'short'
});

export const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

// HTTP

const request = async (getAccessTokenSilently, setIsLoading, url, { body, withoutAuth = false, ...params } = {}) => {
    const impersonateEmail = localStorage.getItem('impersonateEmail');
    setIsLoading(true);
    const token = !withoutAuth && await getAccessTokenSilently();
    const res = await fetch(url, {
        ...params,
        ...(body ? { body: JSON.stringify(snakecase(body, { deep: true })) } : {}),
        headers: {
            'Content-Type': 'application/json',
            ...(withoutAuth ? {} : { 'Authorization': `Bearer ${token}` }),
            ...(impersonateEmail ? { 'X-Impersonate-Email': impersonateEmail } : {})
        }
    });
    const data = await res.json();
    const camelcaseData = camelcase(data, { deep: true });
    console.log('Request: ', url, camelcaseData);
    setIsLoading(false);
    return {
        data: camelcaseData,
        status: res.status
    };
}

export const useHTTPRequest = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [isLoading, setIsLoading] = useState(false);
    const requestFn = useCallback(
        (...args) => request(getAccessTokenSilently, setIsLoading, ...args),
        [getAccessTokenSilently]
    );

    return { request: requestFn, isLoading };
}


export const isSetEqual = (as, bs) => {
    if (as.size !== bs.size) return false;
    for (let a of as) if (!bs.has(a)) return false;
    return true;
}

export const intersection = (a, b) => new Set([...a].filter(x => b.has(x)));