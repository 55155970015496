import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const QuestionAndAnswer = ({ question, answer }) => (
    answer ? (
        <>
            <Divider/>
            <Box>
                <Typography sx={{ fontSize: 20, mt: 1 }}>{question}</Typography>
                <Typography
                    sx={{ fontSize: 14, my: 1, whiteSpace: 'pre-line', wordBreak: 'break-all' }}
                    color="text.secondary"
                >
                    {answer}
                </Typography>
            </Box>
        </>
    ) : null
);

export const ResponseCard = ({ response: { stop, keep, start, words, skills, activities } }) => {
    return (
        <Box>
            <QuestionAndAnswer
                question="What should I stop doing?"
                answer={stop}
            />
            <QuestionAndAnswer
                question="What should I keep doing?"
                answer={keep}
            />
            <QuestionAndAnswer
                question="What should I start doing?"
                answer={start}
            />
            <QuestionAndAnswer
                question="What words and/or phrases describe me?"
                answer={words}
            />
            <QuestionAndAnswer
                question="What skills and/or knowledge should I improve or learn?"
                answer={skills}
            />
            <QuestionAndAnswer
                question="What activities should I spend more/less time on?"
                answer={activities}
            />
        </Box>
    );
};