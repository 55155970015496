import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSnackbar } from 'notistack';

import { useHTTPRequest } from '../../helpers/utils';
import { useStateContext } from '../../context/State';
import { CONSTANTS } from '../../constants';

export const SurveyForm = ({ survey }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const { enqueueSnackbar } = useSnackbar();
    const { dispatch } = useStateContext();
    const { request } = useHTTPRequest();
    const { register, handleSubmit, formState: { errors, isSubmitting, isDirty, isValid } } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: survey.name || 'Unnamed Survey'
        }
    });

    const onFormSubmit = async ({ name }) => {
        try {
            const { status } = await request(`${CONSTANTS.API_DOMAIN}/survey/${survey.surveyId}`, {
                method: 'PATCH',
                body: { name }
            });

            if (status !== 200) {
                enqueueSnackbar('Cannot update the survey.', { variant: 'error' });
                return;
            }

            dispatch({
                type: 'updateSurvey',
                payload: {
                    surveyId: survey.surveyId,
                    name
                }
            });

            enqueueSnackbar(`Survey updated.`, { variant: 'success' });
        } catch (err) {
            enqueueSnackbar(err.message ?? 'Cannot update the survey.', { variant: 'error' });
            console.error('Error updating survey.', err);
        }
    }

    return (
        <Box sx={{display: 'flex', justifyContent: 'center', flexDirection: 'row'}}>
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <TextField
                    sx={{ minWidth: 300, flexGrow: 1, mx: matches ? 0 : 2, mb: 2 }}
                    label="Name"
                    required
                    variant="outlined"
                    disabled={isSubmitting}
                    error={Boolean(errors?.name)}
                    helperText={errors?.name?.message || ' '}
                    {...register('name', { required: 'The survey name is required.' })}
                />
                <LoadingButton
                    sx={{ mx: 2, mb: 2, p: 2 }}
                    variant="contained"
                    type="submit"
                    endIcon={<SendIcon/>}
                    loading={isSubmitting}
                    disabled={!isDirty || !isValid}
                    loadingPosition="end"
                >
                    Save
                </LoadingButton>
            </form>
        </Box>
    )
};