import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAuth0 } from '@auth0/auth0-react';
import { styled } from '@mui/system';

import { Logo } from './Logo';
import { countUnfilledInvites } from '../helpers/utils';
import { useStateContext } from '../context/State';

const NavLink = ({ sx, to, flex, children }) => (
    <Link
        component={RouterLink}
        underline="none"
        color="inherit"
        to={to}
        flex={flex}
    >
        <Typography sx={sx}>
            {children}
        </Typography>
    </Link>
);

const RequestsLink = ({ sx, flex }) => {
    const { state: { invites } } = useStateContext();
    return (
        <NavLink sx={sx} flex={flex} to="/requests">
            <Badge badgeContent={countUnfilledInvites(invites)} color="primary">
                Requests
            </Badge>
        </NavLink>
    )
}

const MobileBurger = ({ logoutButton }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return <>
        <IconButton
            size="large"
            aria-label="Menu button"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
        >
            <MenuIcon/>
        </IconButton>
        <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuItem onClick={handleClose}>
                <NavLink to="/" flex={1}>Home</NavLink>
            </MenuItem>
            <MenuItem onClick={handleClose}>
                <RequestsLink flex={1}/>
            </MenuItem>
            <MenuItem onClick={handleClose}>
                <NavLink to="/about-hos-basecamp" flex={1}>About hOS Basecamp</NavLink>
            </MenuItem>
            {logoutButton ? <MenuItem onClick={handleClose}>{logoutButton}</MenuItem> : null}
        </Menu>
    </>
}

const NavBarComp = ({ className }) => {
    const { state: { roles } } = useStateContext();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const { logout, isAuthenticated } = useAuth0();

    const handleSignOut = () => {
        logout({ returnTo: window.location.origin });
    }

    const mobileLogoutButton = isAuthenticated ?
        <Typography flex={1} onClick={handleSignOut} role="button">Sign Out</Typography> : null;

    const desktopLogoutButton = isAuthenticated ?
        <Button color="inherit" onClick={handleSignOut}>Sign Out</Button> : null;

    if (roles.has('basecamp-participant') || roles.has('admin')) {
        return (
            <AppBar className={className} position="static" sx={{ bgcolor: 'text.primary' }}>
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ mr: 4 }}>
                        <Link component={RouterLink} underline="none" color="inherit" to="/">
                            <Logo/>
                        </Link>
                    </Typography>
                    {matches ? <>
                        <Divider orientation="vertical" variant="middle" flexItem sx={{ mr: 3 }}/>
                        <NavLink sx={{ mx: 1 }} to="/">Home</NavLink>
                        <RequestsLink sx={{ mx: 1 }}/>
                        <NavLink sx={{ mx: 1 }} to="/about-hos-basecamp">About hOS Basecamp</NavLink>
                        {roles.has('admin') ? (
                            <NavLink sx={{ mx: 1 }} to="/impersonate-user">Impersonate user</NavLink>
                        ) : null}
                    </> : null}
                    <Box sx={{ flexGrow: 1 }}/>
                    {matches ? desktopLogoutButton : <MobileBurger logoutButton={mobileLogoutButton}/>}
                </Toolbar>
            </AppBar>
        );
    }

    return (
        <AppBar className={className} position="static" sx={{ bgcolor: 'text.primary' }}>
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ mr: 4 }}>
                    <Link component={RouterLink} underline="none" color="inherit" to="/">
                        <Logo/>
                    </Link>
                </Typography>
                <Box sx={{ flexGrow: 1 }}/>
                {desktopLogoutButton}
            </Toolbar>
        </AppBar>
    )
}

export const NavBar = styled(NavBarComp)`
  @media print {
    display: none;
  }
`;