import { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import T from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import { useSnackbar } from 'notistack';

import { SurveyResponseForm } from '../components/forms/SurveyResponseForm';
import { SurveyState } from '../components/SurveyState';
import { useStateContext } from '../context/State';
import { useHTTPRequest } from '../helpers/utils';
import { useAuth0 } from '@auth0/auth0-react';
import { CONSTANTS } from '../constants';

const BlogLink = ({ text }) => (
    <Link href="https://hbr.org/2011/08/three-questions-for-effective-feedback" target="_blank">{text}</Link>
)

export const SurveyPageContainer = ({ isSample, surveyId, invite, onSubmit }) => (
    <Container>
        <Card sx={{ minWidth: 275 }}>
            <CardContent>
                <T sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Please read carefully
                </T>
                <T align="center" sx={{ my: 2 }} variant="h5">
                    SKS feedback form for <b>{invite.requesterEmail}</b>
                </T>
                <T align="center" sx={{ my: 2, mx: 'auto', maxWidth: 800 }}>The individual who sent you this is
                    looking for your
                    feedback.<br/>Our feedback model is based on SKS (Stop doing, Keep doing, Start doing) which is
                    designed
                    to provide behavior-specific feedback to build for the future.<br/>
                    You can read about it <BlogLink text="here"/>
                </T>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <ul>
                        <li>
                            <T>Your answers will remain completely anonymous, unless you uncheck the anonymous checkbox
                                below</T>
                        </li>
                        <li>
                            <T>The only person that will see your responses is the individual that asked you for the
                                feedback</T>
                        </li>
                        <li><T>Please keep your answers honest and respectful</T></li>
                        <li><T>Details, including examples, are very helpful for putting the feedback into action</T>
                        </li>
                        <li><T>You can fill out the form in any language</T></li>
                        <li><T>We estimate this form will take 10-30 minutes to complete, depending on how much detail
                            you include.</T></li>
                    </ul>
                </Box>
            </CardContent>
        </Card>
        <SurveyResponseForm
            isSample={isSample}
            surveyId={surveyId}
            inviteId={invite.inviteId}
            toEmail={invite.requesterEmail}
            onSubmit={onSubmit}
        />
    </Container>
)

export const SurveyPage = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { id: surveyId, inviteId = '' } = useParams();
    const { state: { invites }, dispatch } = useStateContext();
    const [submitted, setSubmitted] = useState(false);
    const { user } = useAuth0();
    const { request } = useHTTPRequest();
    const [invite, setInvite] = useState(invites?.find(
        ({ surveyId: sid, inviteId: iid }) => sid === surveyId && (!inviteId || iid === inviteId)
    ));
    const [notFound, setNotFound] = useState(false);
    const [multipleInvitesError, setMultipleInvitesError] = useState(false);

    const onSubmit = async ({ success }) => {
        if (success) {
            setSubmitted(true);
            enqueueSnackbar('Feedback submitted.', { variant: 'success' });
        } else {
            enqueueSnackbar('Error. Cannot submit feedback.', { variant: 'error' });
        }
    };

    useEffect(() => {
        if (!invite) {
            const getSurvey = async () => {
                const { data, status } = await request(`${CONSTANTS.API_DOMAIN}/invite/${surveyId}/${inviteId}`, {
                    withoutAuth: true,
                });
                if (status === 404) {
                    setNotFound(true);
                    return;
                }
                if (status === 409) {
                    setMultipleInvitesError(true);
                    return;
                }
                setInvite(data);
                dispatch({
                    type: 'fetchInvite',
                    payload: data,
                })
            }
            getSurvey();
        }
    }, [invite, dispatch, request, surveyId, inviteId])

    if (notFound) {
        return (
            <T align="center" variant="h5" sx={{ my: 5 }}>
                Survey does not exist.
            </T>
        )
    }

    if (multipleInvitesError) {
        return <Redirect to={`/survey/${surveyId}`}/>
    }

    if (!invite) {
        return null;
    }

    if (!inviteId && invite.email !== user?.email) {
        return (
            <T align="center" variant="h5" sx={{ my: 5 }}>
                You are signed in as <b>{user.email}</b><br/><br/>
                To be able to provide a feedback for <b>{invite.requesterEmail}</b><br/><br/>
                You have to Log out and Sign In or Sign Up as <b>{invite.email}</b><br/><br/>
            </T>
        )
    }

    if (submitted || invite.response) {
        return <SurveyState alreadyProvided={!submitted && invite.response}/>
    }

    return (
        <SurveyPageContainer
            surveyId={surveyId}
            invite={invite}
            onSubmit={onSubmit}
        />
    )
};

export const SurveyPageSample = () => (
    <SurveyPageContainer
        isSample
        surveyId={'surveyId'}
        invite={{ requesterEmail: 'sample@sample.com' }}
    />
);