import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSnackbar } from 'notistack';

import { emailPattern, useHTTPRequest } from '../../helpers/utils';
import { useStateContext } from '../../context/State';
import { CONSTANTS } from '../../constants';

export const AddSurveyInviteForm = ({ invites, surveyId, invitesLimit }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const { enqueueSnackbar } = useSnackbar();
    const { dispatch } = useStateContext();
    const { request } = useHTTPRequest();
    const { register, handleSubmit, reset, formState: { errors, isSubmitting, isDirty, isValid } } = useForm({
        mode: 'onChange'
    });
    const limitExceeded = invites.length >= invitesLimit;
    const formDisabled = isSubmitting || limitExceeded;

    const onFormSubmit = async ({ name, email }) => {
        try {
            const { data } = await request(`${CONSTANTS.API_DOMAIN}/invite`, {
                method: 'POST',
                body: {
                    name,
                    email,
                    surveyId
                }
            });

            if (data?.errors?.length) {
                throw new Error(data.errors[0]);
            }

            dispatch({
                type: 'addInvite',
                payload: data
            })

            reset();
            enqueueSnackbar(`Email has been sent ${name}`, { variant: 'success' });
        } catch (err) {
            enqueueSnackbar(err.message ?? 'Cannot create survey invite.', { variant: 'error' });
            console.error('Error adding survey response', err);
        }
    }

    const uniqueEmail = useCallback((value) => (
        invites.find(({ email }) => email === value) ? 'User with this email is already invited.' : true
    ), [invites]);

    return (
        <Box>
            {limitExceeded ? (
                <Typography textAlign="center">
                    The maximum amount of invites is {invitesLimit}. You have invited the maximum amount of persons.
                </Typography>
            ) : (
                <Typography textAlign="center">
                    Add the full name and email address of each person that you’d like to send a feedback request below.<br/>The
                    maximum amount of invites is {invitesLimit}.
                </Typography>
            )}
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <Container maxWidth="md"
                           sx={{ my: 5, p: 0, display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                    <TextField
                        required
                        sx={{ minWidth: 300, flexGrow: 1, mx: matches ? 0 : 2, mb: 2 }}
                        label="Name"
                        variant="outlined"
                        disabled={formDisabled}
                        {...register('name', { required: true })}
                    />
                    <TextField
                        required
                        sx={{ minWidth: 300, flexGrow: 1, mx: matches ? 0 : 2, mb: 2 }}
                        label="Email"
                        variant="outlined"
                        type="email"
                        disabled={formDisabled}
                        error={Boolean(errors?.email?.message)}
                        helperText={errors?.email?.message}
                        {...register('email', { required: true, pattern: emailPattern, validate: uniqueEmail })}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 2 }}>
                        <LoadingButton
                            sx={{ mx: 2, mb: 2 }}
                            variant="contained"
                            type="submit"
                            endIcon={<SendIcon/>}
                            loading={isSubmitting}
                            disabled={!isDirty || !isValid}
                            loadingPosition="end"
                        >
                            Request Feedback
                        </LoadingButton>
                    </Box>
                </Container>
            </form>
        </Box>
    )
};