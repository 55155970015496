import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const AboutPage = () => (
    <Box>
        <Typography sx={{mb: 2}} variant="h4">Introduction to hOS</Typography>
        <Typography sx={{mb: 2}}>We are on a mission to maximize prosperity and minimize suffering. We empower individuals to become
            the best version of themselves, achieve their dreams, and pay it forward by helping other individuals to do
            the same. People have limitless potential, but need support to unlock their individual greatness. Using a
            systematic approach, we help people engineer their prosperity, take back the agency in their lives, and
            eliminate damaging activities. We will use an integrative approach, using programs, technology, and human
            coaching.</Typography>
        <Typography sx={{mb: 2}}>HOS, Inc. was founded in June 2021. While our program is tested with proven results, our company is
            new and we are making massive investments into Basecamp and technology to bring it to the world. We are
            looking for pilot users to join us for Basecamps to both change their lives and share feedback and insights
            to help us grow our product and company.</Typography>
        <Typography sx={{mb: 2}} variant="h4">What is Basecamp?</Typography>
        <Typography sx={{mb: 2}}>Basecamp is our signature event. You spend two days working on every dimension of your life. The
            goal of the program is to refine what you want in each area of life in the short and long term and create a
            plan with a support system to achieve it.</Typography>
        <Typography sx={{mb: 2}} variant="h4">Process</Typography>
        <Typography sx={{mb: 2}}>We break life down into 8 different dimensions - Health, Career, Finances, Family & Friends, Impact,
            Spirituality, Experiences & Material Things, and Hobbies & Activities. We’ll start off by examining our
            dream life in 1, 5, 10, and 20+ years. We also consider the type of person we want to be and the knowledge
            and skills we want to have in the future. From there, we examine our current reality and compare it to our
            goals. Finally, we plan for accountability, motivation, and support to ensure the success of our
            plan.</Typography>
        <Typography sx={{mb: 2}} variant="h4">Why us?</Typography>
        <Typography sx={{mb: 2}}>We built this program to support the goals and growth of our team at a 1,300 person company and saw
            incredible results. We had people lose 40+ pounds, quit smoking, fundamentally change their career to align
            with their passions, improve their relationship with their children/spouse/parents, purchase their first
            home, and much more. Based on the impact we observed, we decided to make this program a separate company to
            help everyone. We are obsessed with helping others improve themselves and achieve their dreams, whatever
            they may be. We hold no judgement and are relentlessly supportive.</Typography>
    </Box>
)