import { Redirect, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import T from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';

import { Auth } from './Auth';
import { Footer } from './Footer';
import { ErrorBoundary } from './ErrorBoundary';
import { NavBar } from './NavBar';
import { useStateContext } from '../context/State';
import { intersection } from '../helpers/utils';


const AuthPageComp = ({ className }) => (
    <Box className={className} sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
            <Auth/>
        </Box>
        <Footer/>
    </Box>
)


const AuthPage = styled(AuthPageComp)`
  background-image: url(/landing_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;


export const AppRoute = ({ children, allowedRoles, isPrivate, ...rest }) => {
    const { isAuthenticated, isLoading } = useAuth0();
    const { state: { loaded, roles } } = useStateContext();

    const render = () => {
        if (isLoading || (isAuthenticated && !loaded)) {
            return (
                <Box>
                    <LinearProgress/>
                    <T align="center" variant="h3" sx={{ mt: '40vh' }}>SKS Feedback</T>
                </Box>
            )
        }

        if (allowedRoles && intersection(roles, allowedRoles).size === 0) {
            return <Redirect to="/"/>
        }

        if (!isAuthenticated && isPrivate) {
            return <AuthPage/>
        }

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                {isAuthenticated ? <NavBar/> : null}
                <ErrorBoundary>
                    <Container maxWidth="lg" sx={{ flexGrow: 1, py: 5 }}>
                        {children}
                    </Container>
                </ErrorBoundary>
                <Footer/>
            </Box>
        )
    }


    return <Route render={render} {...rest}/>
}