import React, { useContext, useReducer, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { CONSTANTS } from '../constants';
import { useHTTPRequest } from '../helpers/utils';

export const StateContext = React.createContext({});
StateContext.displayName = 'StateContext';

const initialState = {
    surveys: null,
    invites: null,
    loaded: false,
    roles: new Set()
};

const stateReducer = (state, { type, payload }) => {
    console.log('State:', state);
    console.log('Action:', { type, payload });
    switch (type) {
        case 'fetchAppState': {
            return { ...state, ...payload };
        }
        case 'updateSurvey': {
            const { surveys } = state;
            const surveyIndex = surveys.findIndex(s => s.surveyId === payload.surveyId);
            const survey = surveys[surveyIndex];
            return {
                ...state, surveys: [
                    ...surveys.slice(0, surveyIndex),
                    { ...survey, ...payload },
                    ...surveys.slice(surveyIndex + 1)
                ]
            };
        }
        case 'fetchInvite': {
            return {
                ...state,
                invites: state?.invites ? [...state.invites, payload] : [payload]
            };
        }
        case 'addInvite': {
            const { surveys } = state;
            const surveyIndex = surveys.findIndex(s => s.surveyId === payload.surveyId);
            const survey = surveys[surveyIndex];
            const invites = [...survey.invites, payload];
            return {
                ...state, surveys: [
                    ...surveys.slice(0, surveyIndex),
                    { ...survey, invites },
                    ...surveys.slice(surveyIndex + 1)
                ]
            };
        }
        case 'newInvite': {
            return { ...state, invites: [...state.invites, payload] };
        }
        case 'newResponse': {
            return { ...state, responses: [...state.responses, payload] }
        }
        case 'sendResponse': {
            const inviteIndex = state.invites.findIndex(({ surveyId }) => surveyId === payload.surveyId);
            const invite = { ...state.invites[inviteIndex], response: payload.response };
            return {
                ...state, invites: [
                    ...state.invites.slice(0, inviteIndex),
                    invite,
                    ...state.invites.slice(inviteIndex + 1),
                ]
            };
        }
        default: {
            throw new Error();
        }
    }
};

export const isAdmin = user => user.email.match(/@hosinc\.co/) && user.email_verified;

export const StateProvider = ({ children }) => {
    const { isLoading, isAuthenticated, user } = useAuth0();
    const { request } = useHTTPRequest();
    const [state, dispatch] = useReducer(stateReducer, { ...initialState });

    useEffect(() => {
        user && console.log('User email: ', user.email);
    }, [user]);

    useEffect(() => {
        const fetchAppData = async () => {
            const { data } = await request(`${CONSTANTS.API_DOMAIN}/sks`);
            const roles = new Set();
            roles.add(data?.surveys?.length > 0 ? 'basecamp-participant' : 'respondent');
            if (isAdmin(user)) {
                roles.add('admin');
            }
            dispatch({
                type: 'fetchAppState',
                payload: {
                    ...data,
                    roles,
                    loaded: true
                }
            })
        }
        if (!isLoading && isAuthenticated) {
            fetchAppData();
        }
    }, [isLoading, isAuthenticated, user, request]);

    return (
        <StateContext.Provider value={{ state, dispatch }}>
            {children}
        </StateContext.Provider>
    )
};

export const useStateContext = () => useContext(StateContext);