import {useParams } from 'react-router-dom';

import Box from '@mui/material/Box';

import { useStateContext } from '../context/State';
import { AddSurveyInviteForm } from '../components/forms/AddSurveyInviteForm';
import { RespondentsTable } from '../components/RespondentsTable';
import { SurveyForm } from '../components/forms/SurveyForm';

export const EditSurveyPage = () => {
    const { id: surveyId } = useParams();
    const { state: { surveys } } = useStateContext();
    const survey = surveys.find(s => s.surveyId === surveyId);

    return (
        <Box>
            <SurveyForm survey={survey}/>
            <AddSurveyInviteForm
                invitesLimit={survey.maxInvitesPerSurvey}
                invites={survey.invites}
                surveyId={survey.surveyId}
            />
            <RespondentsTable invites={survey.invites}/>
        </Box>
    )
};