import { Component } from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <Container maxWidth="lg" sx={{ display: 'flex', flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
                <Box>
                    <Typography align="center" variant="h4">Something went wrong.</Typography>
                    <Typography align="center" sx={{ mt: 2 }} fontSize={14} color="text.secondary">Please reload the
                        page.</Typography>
                </Box>
            </Container>
        }

        return this.props.children;
    }
}