import T from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Countdown from 'react-countdown';
import LockClockIcon from '@mui/icons-material/LockClock';

const DateTime = ({ days, hours, minutes, seconds }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const basicFontSize = matches ? 15 : 30;

    return (
        <T align="center" sx={{ my: 2 }} fontSize={basicFontSize}>
            <T component="span" sx={{ fontSize: '2em' }}>{days} </T>
            <T component="span" sx={{ fontSize: '1em' }}>days </T>
            <T component="span" sx={{ fontSize: '2em' }}>{hours} </T>
            <T component="span" sx={{ fontSize: '1em' }}>hr </T>
            <T component="span" sx={{ fontSize: '2em' }}>{minutes} </T>
            <T component="span" sx={{ fontSize: '1em' }}>min </T>
            <T component="span" sx={{ fontSize: '2em' }}>{seconds} </T>
            <T component="span" sx={{ fontSize: '1em' }}>sec </T>
        </T>
    )
};

const renderer = (props) => <DateTime {...props}/>

export const ResponsesCountdown = ({ responsesVisibilityDatetime }) => {
    return (
        <Box>
            <T align="center" variant="h5">The feedback will become available in</T>
            <Countdown date={responsesVisibilityDatetime} renderer={renderer}/>
            <T align="center"><LockClockIcon fontSize="large"/></T>
        </Box>
    )
};